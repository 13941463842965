import React, { useEffect, useState, useRef } from 'react'
import styles from './index.module.scss'
import { reqOpenId, reqReceiveBinding } from '@/service/info'
import { useLocation } from 'react-router-dom'
import { Form, Input, Button, Toast } from 'antd-mobile'

const Info: React.FC = () => {
    const localtion = useLocation()
    const search = localtion?.search
    const tenant = search?.split('=')[1]
    const end = tenant?.indexOf('&')
    const code = tenant?.substring(0, end) === '' ? tenant : tenant?.substring(0, end)
    const renderRef = useRef(false)
    const [openId, setOpenId] = useState<string>('')

    const initFun = async () => {
        try {
            const openIdInfo = await reqOpenId({ code })
            if (openIdInfo.code === 200) {
                setOpenId(openIdInfo.data || '')
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (!renderRef.current) {
            renderRef.current = true
            initFun()
        }
        document.title = '分期通知'
    }, [])

    const onFinish = async (value: any) => {
        const params = {
            ...value,
            bindingType: 1,
            openId: openId,
        }
        try {
            const openIdInfo = await reqReceiveBinding(params)
            if (openIdInfo.code === 200) {
                Toast.show({
                    content: '订阅成功！',
                    position: 'top',
                })
            } else {
                Toast.show({
                    content: openIdInfo.msg || '订阅失败！',
                    position: 'top',
                })
            }
        } catch (error) {
            Toast.show({
                content: '订阅失败！',
                position: 'top',
            })
        }
    }
    return (
        <div className="bg_gray">
            <div className={['bg_white', styles.blockOne].join(' ')}>
                <div className={styles.priceBox}>
                    <div className="p1">表单</div>
                </div>
                <Form
                    layout="horizontal"
                    onFinish={onFinish}
                    footer={
                        <Button block type="submit" color="primary" size="large">
                            订阅通知
                        </Button>
                    }
                >
                    <Form.Item label="商户名称" name="tenantName">
                        <Input placeholder="请输入商户名称" clearable />
                    </Form.Item>
                    <Form.Item label="商户ID" name="tenantId">
                        <Input placeholder="请输入商户ID" clearable />
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
export default Info
