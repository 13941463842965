import { IRoute } from '@/Interface/common'
import Home from '@/pages/home'
import Info from '@/pages/info'

const routes: IRoute[] = [
    {
        path: '/home',
        component: Home,
        exact: true,
    },
    {
        path: '/',
        component: Home,
        exact: true,
    },
    {
        path: '/info',
        component: Info,
        exact: true,
    },
]
export default routes
