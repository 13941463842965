import { IResData } from '@/Interface/common'
import { IUserInfo } from '@/Interface/home'
import { get, post, put } from '@/utils/request'
export const getUser = (param: Record<string, number>): Promise<IResData<IUserInfo>> => {
    return get({
        cmd: '/system/admin/consumer/user/createUser',
        data: param,
        loading: true,
    })
}

export const getTenantInfo = (param: string): Promise<IResData<IUserInfo>> => {
    return get({
        cmd: '/system/tenant/' + param,
        data: {},
        loading: true,
    })
}

export const markeDefaultAddress = (param: Record<string, any>): Promise<IResData<number>> => {
    return post({
        cmd: '/mall/app/receiving/address/initAddress',
        data: param,
        loading: true,
    })
}

export const virtualSubmit = (param: Record<string, any>): Promise<IResData<number>> => {
    return put({
        cmd: '/mall/app/payment/flow/do/installment',
        data: param,
        loading: true,
    })
}

// 银行列表
export const getBankList = (param: Record<string, number>): Promise<IResData<IUserInfo>> => {
    return get({
        cmd: '/system/bank/query/list',
        data: param,
        loading: true,
    })
}
