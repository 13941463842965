import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { Dialog, Toast } from 'antd-mobile'
import { IResData } from '@/Interface/common'

interface ICusConfig {
    cmd?: string
    data?: Record<string, number | string | object>
    loading?: boolean
}
interface IResquestConfig extends InternalAxiosRequestConfig {
    loading?: boolean
}

let isLoading: boolean = false
axios.defaults.baseURL = process.env.REACT_APP_API_URL

// 添加请求拦截器
axios.interceptors.request.use(
    function (config: IResquestConfig) {
        // 在发送请求之前做些什么
        isLoading &&
            Toast.show({
                icon: 'loading',
                content: '加载中…',
            })
        return config
    },
    function (error) {
        // 对请求错误做些什么
        return Promise.reject(error)
    }
)

// 添加响应拦截器
axios.interceptors.response.use(
    function (response: AxiosResponse<IResData<null>>) {
        // 2xx 范围内的状态码都会触发该函数。
        // 对响应数据做点什么
        Toast.clear()
        const { data } = response
        if (data.code !== 200) {
            Dialog.alert({
                content: data.msg,
            })
        }
        return response
    },
    function (error) {
        Toast.clear()
        // 超出 2xx 范围的状态码都会触发该函数。
        // 对响应错误做点什么
        Dialog.alert({
            content: error.msg || error.message || '请求失败！',
        })
        return Promise.reject(error)
    }
)

export const post = (config: ICusConfig): Promise<IResData> => {
    const { data, cmd, loading } = config
    isLoading = loading ? true : false
    return new Promise((resolve, rejects) => {
        axios({
            method: 'post',
            url: cmd,
            data: data,
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(res => {
                rejects(res)
            })
    })
}

export const put = (config: ICusConfig): Promise<IResData> => {
    const { data, cmd, loading } = config
    isLoading = loading ? true : false
    return new Promise((resolve, rejects) => {
        axios({
            method: 'put',
            url: cmd,
            data: data,
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(res => {
                rejects(res)
            })
    })
}

export const get = (config: ICusConfig): Promise<IResData> => {
    const { data = {}, cmd, loading } = config
    isLoading = loading ? true : false
    const param = Object.keys(data)
    let paramStr = ''
    param.forEach(item => {
        paramStr += item + '=' + data[item] + '&'
    })

    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: cmd + '?' + paramStr,
        })
            .then(res => {
                resolve(res.data)
            })
            .catch(res => {
                reject(res)
            })
    })
}
