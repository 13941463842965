import React, { ChangeEvent, ElementType, useCallback, useEffect, useState, useRef } from 'react'
import styles from './index.module.scss'
import { Toast, Button, Popup, CheckList, Image, Radio, Space } from 'antd-mobile'
import {
    getUser,
    markeDefaultAddress,
    virtualSubmit,
    getTenantInfo,
    getBankList,
} from '@/service/home'
import { useLocation, Link } from 'react-router-dom'
import { IUserInfo } from '@/Interface/home'
import { BankList, SelectBolck } from './pullList'
import bank2 from '@/assest/style/bank2.svg'
import Modal from './ModalList'
import { divideByHundred, bankByHundred } from '@/utils/util'
import copy from '@/assest/style/copy.svg'
import pay1 from '@/assest/style/pay1.svg'
import pay2 from '@/assest/style/pay2.svg'

interface ISbankLists {
    bankCode: string
    bankName: string
    bankType: string
    custom3: number
    custom6: number
    custom9: number
    custom12: number
    icon: string
    sorted: number
    supportInstallments: string
    disabled: boolean
    enable: boolean
}
interface ISpaymentDetails {
    approvalStatus: string
    bankBranchName: string
    bankCard: string
    bankName: string
    contactEmail: string
    contactName: string
    createBy: string
    createTime: string
    customQrCode: string
    delFlag: string
    id: number
    legalFrontIdCardUrl: string
    legalName: string
    legalReverseIdCardUrl: string
    licenseUrl: string
    logo: string
    qrCode: string
    reconciliationLink: string
    remark: string
    showInterest: boolean
    truestatus: number
    tenantAddr: string
    tenantLicenseNo: string
    tenantName: string
    tenantPackage: number
    tenantTime: string
    updateBy: string
    updateTime: string
    userEmail: string
    userName: string
    userPhone: string
    showChannel: boolean
}
const Home: React.FC = () => {
    const localtion = useLocation()
    const search = localtion.search
    const tenant = search.split('=')[1]
    const end = tenant.indexOf('&')
    const tenantId = tenant.substring(0, end) === '' ? tenant : tenant.substring(0, end)
    const [userId, setUserId] = useState<number | null>()
    const renderRef = useRef(false)
    const [price, setPrice] = useState<string>('')

    const [stageValue, setStageValue] = useState<number>(0)
    const [stageBankValue, setStageBankValue] = useState<string>('')

    const [disabled, setDisabled] = useState<boolean>(false)
    const [userInfo, setUserInfo] = useState<IUserInfo>()
    const [tenantName, setTenantName] = useState<string>('')
    const [showInterest, setShowInterest] = useState<boolean>(false)

    const [link, setLink] = useState<string>('')
    const [isOpen, setIsOpen] = useState<boolean>(false)

    const [brankLists, setBrankLists] = useState<ISbankLists[]>()
    const [jointStockBankLists, setJointStockBankLists] = useState<ISbankLists[]>()
    const [brankModalLists, setBrankModalLists] = useState<ISbankLists[]>()
    const [allBankList, setAllBankList] = useState<ISbankLists[]>()
    const [payValue, setPayValue] = useState<string>()
    const [paymentDetails, setPaymentDetails] = useState<ISpaymentDetails[]>()
    const [payList, setPayList] = useState<any>()
    const [showChannel, setShowChannel] = useState<boolean>(false)

    const initFun = async () => {
        try {
            const tenantInfo = await getTenantInfo(tenantId)
            const filterList = (data: any) => {
                const arrList: any = []
                data.map((item: { attachInfoList: any; payType: string }) => {
                    // if (item.payType === 'wechat') {
                    const newArr = item.attachInfoList.find(
                        (str: { payType: string }) => str.payType === '13'
                    )
                    if (newArr !== undefined) {
                        return arrList.push([item])
                    } else {
                        return
                    }
                    // } else if (item.payType === 'ali') {
                    //     const newArr = item.attachInfoList.find(
                    //         (str: { payType: string }) => str.payType === '13'
                    //     )
                    //     if (newArr !== undefined) {
                    //         return arrList.push([item])
                    //     } else {
                    //         return
                    //     }
                    // }
                })
                return arrList?.flat()
            }
            if (tenantInfo.code === 200) {
                setTenantName(tenantInfo.data?.tenantName || '')
                setShowInterest(tenantInfo.data?.showInterest || false)
                setPaymentDetails(tenantInfo?.data)
                setPayList(filterList(tenantInfo?.data?.payChannels))
                setShowChannel(tenantInfo?.data?.showChannel)
            }
            // const userData = await getUser({ tenantId: Number(tenantId) })
            // if (userData.code === 200) {
            //     setUserInfo(userData.data)
            //     setUserId(userData.data?.userId || null)
            //     const addressData = await markeDefaultAddress({
            //         tenantId: Number(tenantId),
            //         userId: userData.data?.userId,
            //     })
            // }
            const resultBankList = await getBankList({ tenantId: Number(tenantId) })
            if (resultBankList.code === 200) {
                const stateOwnedBank = resultBankList.data || []
                const allBank = [
                    ...stateOwnedBank['1'],
                    ...stateOwnedBank['2'],
                    ...stateOwnedBank['3'],
                ].flat()
                setAllBankList(allBank)
                setBrankLists(stateOwnedBank['1'])
                setJointStockBankLists(stateOwnedBank['2'])
                setBrankModalLists(stateOwnedBank['3'])
            }
        } catch (error) {
            console.log(error)
        }
    }

    // 分期
    const stageLists = () => {
        const selectBankList = allBankList?.find(res => res.bankCode === stageBankValue)
        const bankPay = (custom: number) => {
            return bankByHundred(price, custom).toFixed(2).toString() || '0.00'
        }
        return [
            {
                title: `￥${divideByHundred(price, 3).toFixed(2)} x 3期`,
                desc: '0手续费 0利息',
                value: 3,
                text: bankPay(selectBankList?.custom3 || 0),
            },
            {
                title: `￥${divideByHundred(price, 6).toFixed(2)} x 6期`,
                desc: '0手续费 0利息',
                value: 6,
                text: bankPay(selectBankList?.custom6 || 0),
            },
            {
                title: `￥${divideByHundred(price, 9).toFixed(2)} x 9期`,
                desc: '0手续费 0利息',
                value: 9,
                text: bankPay(selectBankList?.custom9 || 0),
            },
            {
                title: `￥${divideByHundred(price, 12).toFixed(2)} x 12期`,
                desc: '0手续费 0利息',
                value: 12,
                text: bankPay(selectBankList?.custom12 || 0),
            },
        ]
    }

    useEffect(() => {
        if (!renderRef.current) {
            renderRef.current = true
            initFun()
        }
    }, [])

    /**
     * 选择分期
     */
    const updateStageValue = useCallback(
        (value: number) => {
            const newArrFn = (arr: any) => {
                return arr?.map((obj: any) => {
                    if (obj.supportInstallments.includes(value)) {
                        return { ...obj, disabled: false }
                    } else {
                        return { ...obj, disabled: true }
                    }
                })
            }
            setStageValue(value)
            setBrankLists(newArrFn(brankLists))
            setJointStockBankLists(newArrFn(jointStockBankLists))
            setBrankModalLists(newArrFn(brankModalLists))
        },
        [brankLists, jointStockBankLists, brankModalLists]
    )
    const priceInputFun = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value
        if (isNaN(Number(value))) {
            Toast.show({
                content: '请输入正确金额！',
                position: 'top',
            })
        }
        // if (!/^[1-9]\d*$/.test(value)) {
        //     Toast.show({
        //         content: '请输入正整数',
        //         position: 'top',
        //     })
        // }
        if (value.toString().length > 7) {
            Toast.show({
                content: '金额不能超过7位数',
                position: 'top',
            })
        }
        setPrice(value)
    }, [])

    /**
     * 点击下一步
     */
    const nextStepFun = useCallback(() => {
        if (isNaN(Number(price))) {
            Toast.show({
                content: '请输入正确金额！',
                position: 'top',
            })
            return
        }
        if (price.toString().length > 7) {
            Toast.show({
                content: '金额不能超过7位数',
                position: 'top',
            })
            return
        }
        // 分期支付
        if (payValue === undefined) {
            if (!/^[1-9]\d*$/.test(price)) {
                Toast.show({
                    content: '请输入正整数',
                    position: 'top',
                })
                return
            }
            if (Number(price) < 100) {
                Toast.show({
                    content: '金额不能少于100.00元',
                    position: 'top',
                })
                return
            }
            if (stageValue.toString() === '') {
                Toast.show({
                    content: '请选择分期',
                    position: 'top',
                })
                return
            }
            if (stageBankValue.toString() === '') {
                Toast.show({
                    content: '请选择银行',
                    position: 'top',
                })
                return
            }
            setLink('')
            const selectBankList = allBankList?.find(res => res.bankCode === stageBankValue)
            virtualSubmit({
                numberOfInstallments: stageValue,
                bankId: selectBankList?.enable ? stageBankValue : '',
                // userId: userId,
                user: search.includes('custom=true') ? 'user' : '',
                tenantId: tenantId,
                totalPrice: price,
            }).then(res => {
                if (res.code == 200) {
                    const link = res.msg
                    if (search.includes('custom=true')) {
                        return window.open(link)
                    } else {
                        setIsOpen(true)
                    }
                    setLink(link)
                }
            })
        } else {
            // 其他方式支付
            setLink('')
            virtualSubmit({
                user: 'channel',
                tenantId: tenantId,
                totalPrice: price,
                pay: 45,
                channel: payValue === 'wechat' ? 31 : payValue === 'ali' ? 32 : 0, //支付方式
            }).then(res => {
                console.log('res', res)
                if (res.code == 200) {
                    const link = res.msg
                    window.open(link)
                    setLink(link)
                }
            })
        }
    }, [price, userId, tenantId, stageValue, stageBankValue, link, payValue])

    const closeModal = () => {
        setIsOpen(false)
    }

    const [isOpenBank, setIsOpenBank] = useState<boolean>(false)

    const handleOpenBank = () => {
        setIsOpenBank(true)
    }

    /**
     * 选择银行
     */
    const updateStageBankValue = useCallback((value: string) => {
        setStageBankValue(value)
    }, [])

    // 复制
    const handCopy = (value: string) => {
        if (value) {
            Toast.show({
                content: '复制成功！',
                position: 'top',
            })
            const pEle = document.createElement('p')
            pEle.innerHTML = value || ''
            document.body.appendChild(pEle)

            const range = document.createRange() // 创造range
            window?.getSelection()?.removeAllRanges() //清除页面中已有的selection
            range.selectNode(pEle) // 选中需要复制的节点
            window?.getSelection()?.addRange(range) // 执行选中元素
            const copyStatus = document.execCommand('Copy') // 执行copy操作
            Toast?.show({
                content: copyStatus ? '复制成功' : '复制失败',
                position: 'top',
            }) // 不需要提示 可注释
            document.body.removeChild(pEle)
            window?.getSelection()?.removeAllRanges() //清除页面中已有的selection
        } else {
            Toast.show({
                content: '复制失败，请手动复制！',
                position: 'top',
            })
        }
    }
    const selectModalBankList = brankModalLists?.find(res => res.bankCode === stageBankValue)
    return (
        <div className="bg_gray">
            <div className={['bg_white', styles.blockOne].join(' ')}>
                <div className={styles.shopContent}>
                    <div className={styles.shop}>
                        <span className={styles.icon}>
                            <i className="iconfont icon-shangdian"></i>
                        </span>
                        <span className="h2">{tenantName}</span>
                    </div>
                    <div className={styles.tenantId}>
                        商户ID: {tenantId}
                        <span className={styles.iconCopy} onClick={() => handCopy(tenantId)}>
                            <Image src={copy} width={20} height={20} fit="fill" />
                        </span>
                    </div>
                </div>

                <div className={styles.priceBox}>
                    <div className="p1">商品金额</div>
                    <div className={styles.priceInput}>
                        ￥
                        <input
                            type="text"
                            placeholder="最低分期金额100元"
                            value={price}
                            onInput={priceInputFun}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.blockTwo}>
                {/* <div className="h1">选择分期</div> */}
                <div className={styles.selectStageBox}>
                    {stageLists()?.map(item => (
                        <SelectBolck
                            {...item}
                            key={item.value}
                            updateStageValue={updateStageValue}
                            index={stageValue}
                            showInterest={showInterest}
                        ></SelectBolck>
                    ))}
                </div>
                <div className={styles.bankTitle}>{brankLists?.length}大国有银行</div>
                <div className={styles.bankStageBox}>
                    {brankLists?.map(item => (
                        <BankList
                            {...item}
                            key={item.bankCode}
                            updateStageValue={updateStageBankValue}
                            index={stageBankValue}
                        ></BankList>
                    ))}
                </div>
                <div className={styles.bankTitle}>{jointStockBankLists?.length}大股份制银行</div>
                <div className={styles.bankStageBox}>
                    {jointStockBankLists?.map(item => (
                        <BankList
                            {...item}
                            key={item.bankCode}
                            updateStageValue={updateStageBankValue}
                            index={stageBankValue}
                        ></BankList>
                    ))}
                </div>
                <div className={styles.bankTitle} onClick={handleOpenBank}>
                    <div>地方银行</div>
                    <div className={styles.bankTitleRight}>
                        {selectModalBankList !== undefined ? (
                            <>
                                <img src={selectModalBankList.icon} className={styles.img} />
                                {selectModalBankList?.bankName}
                            </>
                        ) : (
                            '更多银行'
                        )}
                        <img src={bank2} className={styles.bankTitleRightIcon} />
                    </div>
                </div>
            </div>
            {/* 其他支付方式 */}
            {showChannel && (
                <div className={styles.blockThree}>
                    <div className={styles.payWay}>其他支付方式</div>
                    <Radio.Group
                        value={payValue}
                        onChange={val => {
                            setPayValue(val as string)
                            setStageBankValue('')
                            setStageValue(0)
                        }}
                    >
                        <Space direction="horizontal" className={styles.payRadio}>
                            {payList?.map((item: { payType: string | null | undefined }) => (
                                // eslint-disable-next-line react/jsx-key
                                <Radio key={item?.payType} value={item?.payType || ''}>
                                    {item?.payType === 'wechat' ? (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Image
                                                src={pay2}
                                                width={30}
                                                height={30}
                                                style={{ marginRight: 8 }}
                                                fit="fill"
                                            />
                                            微信支付
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <Image
                                                src={pay1}
                                                width={26}
                                                height={26}
                                                style={{ marginRight: 8 }}
                                                fit="fill"
                                            />
                                            支付宝支付
                                        </div>
                                    )}
                                </Radio>
                            ))}
                        </Space>
                    </Radio.Group>
                </div>
            )}
            <div
                style={{
                    margin: '0.6rem auto',
                    width: '7rem',
                }}
            >
                <Button
                    color="primary"
                    fill="solid"
                    block
                    onClick={nextStepFun}
                    disabled={disabled}
                >
                    下单
                </Button>
            </div>
            {/* 二维码 */}
            <Modal isOpen={isOpen} onClose={closeModal}>
                <div
                    id="formId"
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <img
                        src={link}
                        style={{ maxWidth: '90vw', maxHeight: '80vh', margin: 'auto' }}
                        referrerPolicy="no-referrer"
                    ></img>
                </div>
            </Modal>
            {/* 银行 */}
            <Popup
                visible={isOpenBank}
                showCloseButton
                onClose={() => {
                    setIsOpenBank(false)
                }}
            >
                <div
                    style={{ height: '60vh', overflowY: 'scroll', padding: '10px 20px 20px 20px' }}
                >
                    <div className={styles.bankModalStageBoxTitle}>选择地方银行</div>
                    <div className={styles.bankModalStageBox}>
                        <CheckList
                            onChange={res => {
                                setStageBankValue(res.toString())
                            }}
                        >
                            {brankModalLists?.map(item => (
                                <CheckList.Item
                                    key={item.bankCode}
                                    value={item.bankCode}
                                    disabled={item.disabled}
                                    className={styles.list}
                                >
                                    <img
                                        src={item.icon}
                                        className={[
                                            styles.img,
                                            disabled ? styles.disabledIcon : '',
                                        ].join(' ')}
                                    />
                                    {item.bankName}
                                </CheckList.Item>
                            ))}
                        </CheckList>
                    </div>
                </div>
            </Popup>
        </div>
    )
}
export default Home
