import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import './App.scss'
import './assest/style/fonts.css'
import routes from '@/routes/index'

function App() {
    return (
        <Router>
            <Switch>
                {routes.map((item, index) => (
                    <Route
                        path={item.path}
                        key={index}
                        component={item.component}
                        exact={item.exact}
                    ></Route>
                ))}
            </Switch>
        </Router>
    )
}

export default App
