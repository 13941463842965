import React, { useState } from 'react'
import styles from './index.module.scss'

interface ModalProps {
    isOpen: boolean
    onClose: () => void
    children: React.ReactNode
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
    const [isAnimating, setIsAnimating] = useState(false)

    const closeModal = () => {
        setIsAnimating(true)
        setTimeout(() => {
            setIsAnimating(false)
            onClose()
        }, 300)
    }

    if (!isOpen && !isAnimating) {
        return null
    }

    return (
        <div
            className={[
                styles.modal,
                isOpen ? styles.open : '',
                isAnimating ? styles.animating : '',
            ].join(' ')}
        >
            <div className={styles.modalOverlay} onClick={closeModal} />
            <div className={styles.modalContent}>
                <div className={styles.modalCloseIcon}>
                    <div className={styles.modalClose} onClick={closeModal}>
                        关闭
                    </div>
                </div>

                {children}
            </div>
        </div>
    )
}

export default Modal
