import { IResData } from '@/Interface/common'
import { get, post, put } from '@/utils/request'

// 获取openId
export const reqOpenId = (param: Record<string, any>): Promise<IResData<number>> => {
    return post({
        cmd: '/system/wx/login/openId',
        data: param,
        loading: true,
    })
}

// 接收用户绑定机器通知接口
export const reqReceiveBinding = (param: Record<string, any>): Promise<IResData<number>> => {
    return post({
        cmd: '/system/user/bind/receiveBinding',
        data: param,
        loading: true,
    })
}
