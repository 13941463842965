// 除以100保留两位小数点
export function divideByHundred(str: any, num: number) {
    const strNumber: number = str * 1
    let floatVal = parseFloat(str)
    if (isNaN(floatVal)) {
        return 0
    }
    floatVal = Math.round(strNumber * 1) / num
    let strVal = floatVal.toString()
    let searchVal = strVal.indexOf('.')
    if (searchVal < 0) {
        searchVal = strVal.length
        strVal += '.'
    }
    while (strVal.length <= searchVal + 2) {
        strVal += '0'
    }
    return parseFloat(strVal)
}

// 贴息
export function bankByHundred(str: any, num: any) {
    const strNumber: number = str * 1
    const price: number = num * 1

    let floatVal = parseFloat(str)
    if (isNaN(floatVal)) {
        return 0
    }
    floatVal = Math.round(strNumber * price) / 100
    let strVal = floatVal.toString()
    let searchVal = strVal.indexOf('.')
    if (searchVal < 0) {
        searchVal = strVal.length
        strVal += '.'
    }
    while (strVal.length <= searchVal + 2) {
        strVal += '0'
    }
    return parseFloat(strVal)
}
