import React from 'react'
import styles from './index.module.scss'

/*
 * 分期
 */
interface IStageLists {
    title: string
    desc: string
    value: number
    text: string
}

interface ISelectBlockProps extends IStageLists {
    updateStageValue: (data: number) => void
    index: number
    showInterest: boolean
}
// 分期
export const SelectBolck: React.FC<ISelectBlockProps> = (props: ISelectBlockProps) => {
    const { title, desc, value, text, showInterest, index } = props
    return (
        <div
            className={[styles.stageList, index === value ? styles.select : styles.default].join(
                ' '
            )}
            onClick={() => {
                props.updateStageValue(value)
            }}
        >
            <div>
                <div className={['h3', styles.h3].join('  ')}>{title}</div>
                <div className={['p2', styles.p2].join(' ')}>{desc}</div>
                {/* {showInterest && (
                    <div className={['p2', styles.p2].join(' ')}> 商户贴息:￥{text}</div>
                )} */}
            </div>
        </div>
    )
}

/*
 * 银行
 */
interface ISbankLists {
    bankCode: string
    bankName: string
    bankType: string
    custom3: number
    custom6: number
    custom9: number
    custom12: number
    icon: string
    sorted: number
    supportInstallments: string
    disabled: boolean
}
interface ISBankBlockProps extends ISbankLists {
    updateStageValue: (data: string) => void
    index: string
}
// 银行
export const BankList: React.FC<ISBankBlockProps> = (props: ISBankBlockProps) => {
    const { bankName, icon, bankCode, index, disabled } = props
    return (
        <div
            className={[
                styles.bankList,
                !disabled && index === bankCode ? styles.select : styles.default,
                disabled && styles.disabledStyle,
            ].join(' ')}
            onClick={() => {
                props.updateStageValue(bankCode)
            }}
        >
            <div className={[styles.list, disabled ? styles.disabledStyle : ''].join(' ')}>
                {icon && (
                    <div className={['p2', styles.p2].join(' ')}>
                        <img
                            src={icon}
                            className={[styles.img, disabled ? styles.disabledIcon : ''].join(' ')}
                        />
                    </div>
                )}
                <div className={['h3', styles.h3].join('  ')}>{bankName}</div>
            </div>
        </div>
    )
}
